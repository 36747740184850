import * as React from "react";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import i18n from "../../components/i18n";
import { Trans, withTranslation, useTranslation } from "react-i18next";
import NavigationHelper from "../../helpers/navigation";
import Layout from "../../components/layout";
import ReactJWPlayerContainer from "../../components/react_jwplayer";

import { TestimonialContactForm } from "../../components/form_testimonial_contact";
import ShareButtons from "../../components/share_buttons";
import SocialConfData from "../../conf/social.json";
import Seo from "../../components/seo";

import "../../styles/video-player.css";

/** Images import */
import img_testimonial_banner_background from "../../images/a1_cloud_javelo_aws_testimonial_header_bg.webp";
import img_testimonial_video_cover_img from "../../images/a1_cloud_javelo_video_aws_testimonial_cover.webp";
import img_logo from "../../images/javelo_logo.webp";
import img_tpop_arrow_icon from "../../images/a1_cloud_blue_quote_sign.webp";
import img_photo_man from "../../images/thumb_video_javelo.webp";

// markup
const ClientTestimonialPage = (props) => {
  const [activeLanguage, setActiveLanguage] = useState();
  useEffect(() => {
    setActiveLanguage(i18n.language);
  }, [setActiveLanguage]);

  const { t } = useTranslation(["main", "forms"]);
  const ExtContactForm = withTranslation("forms")(TestimonialContactForm);

  // Navigation info
  const pageInfo = {
    key: "client_testimonial",
    item_key: "javelo",
    lang: i18n.language,
  };

  const cdnBaseUrl = process.env.GATSBY_CDN_URL;
  const videoSubtitlesEnabledLanguages = ["fr"];

  //For the social share buttons
  const title = t("main:social_testimonial_javelo_title_txt");
  const url = props.location.href;
  const twitterHandle = SocialConfData.ids.twitter;
  const tags = [
    t("main:social_testimonial_javelo_tag_javelo_txt"),
    t("main:social_testimonial_javelo_tag_rh_txt"),
    t("main:social_testimonial_javelo_tag_aws_txt"),
    t("main:social_testimonial_javelo_tag_migration_txt"),
  ];

  return (
    <Layout location={props.location} pageInfo={pageInfo}>
      <Helmet
        bodyAttributes={{
          class: "testi-monial-main-page",
        }}
      ></Helmet>

      <Seo
        description={t(
          "main:single-testimonial_javelo-meta_seo-description_txt"
        )}
        title={t("main:single-testimonial_javelo-meta_seo-title_txt")}
        keywords={[
          t("main:single-testimonial_javelo-meta_seo-keyword1_txt"),
          t("main:single-testimonial_javelo-meta_seo-keyword2_txt"),
          t("main:single-testimonial_javelo-meta_seo-keyword3_txt"),
          t("main:single-testimonial_javelo-meta_seo-keyword4_txt"),
          t("main:single-testimonial_javelo-meta_seo-keyword5_txt"),
        ]}
        url={props.location.href}
        image_url={`${props.location.origin}/a1_cloud_javelo_video_aws_testimonial_cover.webp`}
      />

      <div className="main-content">
        {/* breadcumbs */}
        <div className="site-breadcumbs">
          <div className="container">
            <small>
              {" "}
              <a
                href={NavigationHelper.useGeneratePageURL(
                  activeLanguage,
                  "home"
                )}
                title={t("main:global_header_home_menu-item-seo-title_txt")}
              >
                A1:{" "}
              </a>
              //{" "}
              <a
                title={t(
                  "main:global_header_testimonials_menu-item-seo-title_txt"
                )}
                href={NavigationHelper.useGeneratePageURL(
                  activeLanguage,
                  "testimonials"
                )}
              >
                {t("main:testimonials_header_breadcrumb_title")}
              </a>{" "}
              / Javelo
            </small>
          </div>
        </div>
        {/* end breadcumbs */}
        <div
          className="testi_detailhead service_header"
          style={{
            backgroundImage: `url(${img_testimonial_banner_background})`,
          }}
        >
          <div className="container">
            <div className="title_block">
              <h1 className="nos-service">
                <span>{t("main:single-testimonial_header_block_title")} </span>{" "}
                bakari sumaila javelo
              </h1>
            </div>
          </div>
        </div>
        {/* middle section  */}
        <div className="testi-monial-detail-img">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="img-box">
                  <div className="res_img" id="player">
                    {/*
                URLs for video and subtitle files must respect the following naming convention:
                _ video: http|https://<CDN BASE URL>/a1cloud_construire_ensemble_<CLIENT CODENAME>_<LANGUAGE CODE>.mp4
                _ subtitles: http|https://<CDN BASE URL>/a1cloud_construire_ensemble_<CLIENT CODENAME>_<LANGUAGE CODE>.srt
               */}
                    <ReactJWPlayerContainer
                      file={`${cdnBaseUrl}/videos/a1cloud_construire_ensemble_javelo_${i18n.language}.mp4`}
                      captions={`${cdnBaseUrl}/captions/a1cloud_construire_ensemble_javelo_${i18n.language}.srt`}
                      image={img_testimonial_video_cover_img}
                      videoSubtitlesEnabledLanguages={
                        videoSubtitlesEnabledLanguages
                      }
                      title={t(
                        "main:single-testimonial_captions_javelo-video_title"
                      )}
                      i18nLangCode={pageInfo.lang}
                      i18nLabels={{
                        languageNameLabel: t(
                          "video:global_controls_own-language-name_label"
                        ),
                        playLabel: t("video:global_controls_play_label"),
                        rewindLabel: t("video:global_controls_rewind_label"),
                        pipIconLabel: t("video:global_controls_pip-icon_label"),
                        unmuteLabel: t("video:global_controls_unmute_label"),
                        muteLabel: t("video:global_controls_mute_label"),
                        volumeLabel: t("video:global_controls_volume_label"),
                        volumeSliderLabel: t(
                          "video:global_controls_volume-slider_label"
                        ),
                        pauseLabel: t("video:global_controls_pause_label"),
                        settingsLabel: t(
                          "video:global_controls_settings_label"
                        ),
                        ccLabel: t("video:global_controls_cc_label"),
                        offLabel: t("video:global_controls_off_label"),
                        fullscreenLabel: t(
                          "video:global_controls_fullscreen_label"
                        ),
                        exitFullscreenLabel: t(
                          "video:global_controls_exit-fullscreen_label"
                        ),
                        resetLabel: t("video:global_controls_reset_label"),
                        captionsColorLabel: t(
                          "video:global_captions_color_label"
                        ),
                        captionsBgColorLabel: t(
                          "video:global_captions_bg-color_label"
                        ),
                        captionsBgOpacityLabel: t(
                          "video:global_captions_bg-opacity_label"
                        ),
                        captionsBlackLabel: t(
                          "video:global_captions_black_label"
                        ),
                        captionsBlueLabel: t(
                          "video:global_captions_blue_label"
                        ),
                        captionsCharacterEdgeLabel: t(
                          "video:global_captions_character-edge_label"
                        ),
                        captionsCyanLabel: t(
                          "video:global_captions_cyan_label"
                        ),
                        captionsDepressedLabel: t(
                          "video:global_captions_depressed_label"
                        ),
                        captionsDisabledLabel: t(
                          "video:global_captions_disabled_label"
                        ),
                        captionsEnabledLabel: t(
                          "video:global_captions_enabled_label"
                        ),
                        captionsDropShadowLabel: t(
                          "video:global_captions_drop-shadow_label"
                        ),
                        captionsFontFamilyLabel: t(
                          "video:global_captions_font-family_label"
                        ),
                        captionsFontOpacityLabel: t(
                          "video:global_captions_font-opacity_label"
                        ),
                        captionsFontSizeLabel: t(
                          "video:global_captions_font-size_label"
                        ),
                        captionsGreenLabel: t(
                          "video:global_captions_green_label"
                        ),
                        captionsMagentaLabel: t(
                          "video:global_captions_magenta_label"
                        ),
                        captionsNoneLabel: t(
                          "video:global_captions_none_label"
                        ),
                        captionsRaisedLabel: t(
                          "video:global_captions_raised_label"
                        ),
                        captionsRedLabel: t("video:global_captions_red_label"),
                        captionsResetLabel: t(
                          "video:global_captions_reset_label"
                        ),
                        captionsSubtitleSettingsLabel: t(
                          "video:global_captions_subtitle-settings_label"
                        ),
                        captionsUniformLabel: t(
                          "video:global_captions_uniform_label"
                        ),
                        captionsWhiteLabel: t(
                          "video:global_captions_white_label"
                        ),
                        captionsWindowColorLabel: t(
                          "video:global_captions_window-color_label"
                        ),
                        captionsWindowOpacityLabel: t(
                          "video:global_captions_window-opacity_label"
                        ),
                        captionsUserYellowLabel: t(
                          "video:global_captions_yellow_label"
                        ),
                        captionsUserFontScaleLabel: t(
                          "video:global_captions_user-font-scale_label"
                        ),
                        captionsEdgeStyleLabel: t(
                          "video:global_captions_edge-style_label"
                        ),
                      }}
                    />
                    {/* <img src={img_testimonial_video_cover_img} alt="Vidéo-image" /> */}
                    {/* <div className="play-icon">
                <i className="fa fa-play" aria-hidden="true" />
              </div> */}
                  </div>
                  {/* <img className="a_sign" src="images/white_a.png" /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="testi-monial-detail-content">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="text-block">
                  <div className="text-block-img">
                    <span className="quot_img">
                      <img src={img_tpop_arrow_icon} />
                    </span>
                    <div className="user_img">
                      <img
                        className="thumbnail_img"
                        src={img_photo_man}
                        alt={t("main:global_person_photo-alt_txt", {
                          name: "Bakari Sumaila",
                        })}
                      />
                    </div>
                  </div>
                  <div className="text-block-right-text">
                    <h2>
                      <q>{t("main:testimonials_main_javelo_title")}</q>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="service_detail testi-monial-detail-middle">
          <div className="container">
            <div className="block_box">
              <div className="service-page-box-row first-box testi-monial-detail-middle-left">
                <div className="service-page-box-row-text">
                  <img className="bg_img" src={img_logo} alt="top-bg" />
                  <h4>{t("main:single-testimonial_main_summary_title")}</h4>
                  <h3>
                    {t("main:single-testimonial_main_javelo-company-name_txt")}
                  </h3>
                  <ul className="rejoindre-page-detail-left-ul">
                    <li>
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                        >
                          <path d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z" />
                        </svg>
                      </span>
                      <p>
                        {" "}
                        {t(
                          "main:single-testimonial_client-info_javelo-city_txt"
                        )}
                      </p>
                    </li>
                    <li>
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                        >
                          <path d="M120 72c0-39.765 32.235-72 72-72s72 32.235 72 72c0 39.764-32.235 72-72 72s-72-32.236-72-72zm254.627 1.373c-12.496-12.497-32.758-12.497-45.254 0L242.745 160H141.254L54.627 73.373c-12.496-12.497-32.758-12.497-45.254 0-12.497 12.497-12.497 32.758 0 45.255L104 213.254V480c0 17.673 14.327 32 32 32h16c17.673 0 32-14.327 32-32V368h16v112c0 17.673 14.327 32 32 32h16c17.673 0 32-14.327 32-32V213.254l94.627-94.627c12.497-12.497 12.497-32.757 0-45.254z" />
                        </svg>
                      </span>
                      <p>
                        {" "}
                        50{" "}
                        {t(
                          "main:single-testimonial_main_summary-employees_txt"
                        )}
                      </p>
                    </li>
                    <li>
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 640 512"
                        >
                          <path d="M537.6 226.6c4.1-10.7 6.4-22.4 6.4-34.6 0-53-43-96-96-96-19.7 0-38.1 6-53.3 16.2C367 64.2 315.3 32 256 32c-88.4 0-160 71.6-160 160 0 2.7.1 5.4.2 8.1C40.2 219.8 0 273.2 0 336c0 79.5 64.5 144 144 144h368c70.7 0 128-57.3 128-128 0-61.9-44-113.6-102.4-125.4z" />
                        </svg>
                      </span>
                      <p>
                        {" "}
                        {t(
                          "main:single-testimonial_client-info_javelo-stack_txt"
                        )}
                      </p>
                    </li>
                    <li>
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                        >
                          <path d="M148 288h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm108-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm96 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm-96 96v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm-96 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm192 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm96-260v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h48V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h128V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h48c26.5 0 48 21.5 48 48zm-48 346V160H48v298c0 3.3 2.7 6 6 6h340c3.3 0 6-2.7 6-6z" />
                        </svg>
                      </span>
                      <p>
                        {" "}
                        {t(
                          "main:single-testimonial_main_summary-date-september-2021_txt"
                        )}
                      </p>
                    </li>
                  </ul>
                  <ul className="social_links">
                    <b>
                      {" "}
                      {t("main:single-testimonial_main_summary-share_txt")}
                    </b>
                    <ShareButtons
                      title={title}
                      url={url}
                      twitterHandle={twitterHandle}
                      tags={tags}
                    />
                  </ul>
                </div>
              </div>
            </div>
            <div className="middle_content">
              <h5>
                {t("main:single-testimonial_main_javelo-interviewee-name_txt")}
              </h5>
              <span>
                {t(
                  "main:single-testimonial_main_javelo-interviewee-position_txt"
                )}
              </span>
              <hr />
              <Trans i18nKey="main:single-testimonial_main_javelo-intro-p1_html">
                <p>
                  <i>
                    Javelo a fait appel à A1 Cloud courant 2021 pour mener à
                    bien un projet de migration d'infrastructure d'Heroku vers
                    AWS.
                  </i>
                </p>
              </Trans>
              <Trans i18nKey="main:single-testimonial_main_javelo-intro-p2_html">
                <p>
                  <i>
                    Bakari Sumaila, CTO de Javelo nous apporte quelques
                    éclairages sur cette collaboration.
                  </i>
                </p>
              </Trans>
              <hr />
              <p>
                <h4>{t("main:single-testimonial_main_javelo-itw-q1_html")}</h4>
              </p>
              <Trans i18nKey="single-testimonial_main_javelo-itw-q1-answer_html">
                <p>
                  L’équipe technique de Javelo est composée de 11 ingénieurs
                  backend au profils différents: backend, frontend, full stack
                  et QA.
                </p>
                <p>
                  Les principales technologies utilisées: Ruby, Ruby on Rails,
                  ReactJs, PostgreSQL, Redis, Elasticsearch, AWS, Terraform.
                </p>
              </Trans>
              <p>
                <h4>{t("main:single-testimonial_main_javelo-itw-q2_html")}</h4>
              </p>
              <Trans i18nKey="single-testimonial_main_javelo-itw-q2-answer_html">
                <p>
                  Nous connaissons une croissance soutenue, et bien que déjà
                  positionnés sur des technologies cloud nous avions besoin de
                  faire évoluer l'architecture de notre application afin de
                  répondre à nos enjeux de croissance.
                </p>
                <p>
                  La plateforme PaaS Heroku ne nous permettait pas d'avoir la
                  diversité des services et la maîtrise sur chacun d'eux dont
                  nous avions besoin pour passer à l'échelle.
                </p>
              </Trans>
              <p>
                <h4>{t("main:single-testimonial_main_javelo-itw-q3_html")}</h4>
              </p>
              <Trans i18nKey="single-testimonial_main_javelo-itw-q3-answer_html">
                <p>
                  Tout d'abord, la mise en place d'une nouvelle infrastructure
                  demandait d'y consacrer une grande partie de nos ressources
                  internes, ce qui mettait en danger le respect de certains
                  engagements vis-à-vis de notre roadmap.
                </p>
                <p>
                  Ensuite, la crainte d'introduire de la dette technique dès les
                  premières étapes de création de la nouvelle infrastructure
                  était réelle.
                </p>
                <p>
                  Pour finir, l'échéance de la mise en place de la nouvelle
                  infrastructure constituait également une contrainte majeure,
                  car notre traffic est saisonnier, et la haute saison se
                  rapprochait.
                </p>
              </Trans>
              <p>
                <h4>{t("main:single-testimonial_main_javelo-itw-q4_html")}</h4>
              </p>
              <Trans i18nKey="single-testimonial_main_javelo-itw-q4-answer_html">
                <p>
                  A1 nous a apporté un accompagnement complet sur les
                  différentes phases du projet:
                </p>
                <p>
                  D'abord dans la phase de conception où nous avons élaboré une
                  architecture sur-mesure qui répond exactement aux besoins de
                  notre applicatif et au mode de travail de nos équipes.
                </p>
                <p>
                  Ensuite, durant la phase de construction de l'infrastructure,
                  qui n'a pas uniquement consisté à nous livrer du code et des
                  ressources d'infrastructure, mais aussi à organiser entre
                  Javelo et A1 une transmission de connaissances en continu pour
                  permettre à notre équipe d'ingénieurs de monter en compétence
                  et reprendre en main l'infrastructure à l'issue de la
                  livraison, en toute confiance.
                </p>
              </Trans>
              <p>
                <h4>{t("main:single-testimonial_main_javelo-itw-q5_html")}</h4>
              </p>
              <Trans i18nKey="single-testimonial_main_javelo-itw-q5-answer_html">
                <p>
                  Premièrement, leur utilisation de l'
                  <i>Infrastructure as Code</i> est claire et efficace.
                </p>
                <p>
                  Ensuite, la plateforme est robuste et nous apporte les
                  performances dont nous avions besoin.
                </p>
                <p>
                  Et quelques mois après la livraison, la plateforme a été
                  auditée. Ces audits ont été passés haut la main! Nous avions
                  confiance dans la qualité du travail fourni, car nous avions
                  été intégrés aux revues de code durant la construction, mais
                  la confirmation provenant d'un tiers externe apporte
                  objectivement encore plus de confiance.
                </p>
              </Trans>
              <p>
                <h4>{t("main:single-testimonial_main_javelo-itw-q6_html")}</h4>
              </p>
              <Trans i18nKey="single-testimonial_main_javelo-itw-q6-answer_html">
                <p>
                  Développer de nouvelles fonctionnalités plus avancées,
                  notamment dans le domaine du <i>Machine Learning</i> grâce aux
                  outils et capacités techniques que nous offre notre nouvelle
                  infrastructure.
                </p>
              </Trans>
              <hr />
              <Trans i18nKey="main:single-testimonial_main_javelo-itw-conclusion_html">
                <p>
                  <i>
                    A1 Cloud remercie Anne-Sophie Vasseur, Bakari Sumaila et
                    toute l'équipe Javelo pour la confiance accordée et la
                    reconnaissance témoignée.
                  </i>
                </p>
              </Trans>
            </div>
            <div className="form_block desktop_view">
              <h3>
                <Trans i18nKey="main:single-testimonial_contact_form-sidechat-title_html">
                  Comme {{ clientname: "Javelo" }} contactez-nous et avançons
                  ensemble!
                </Trans>
              </h3>
              <ExtContactForm
                contact_source={`${pageInfo.item_key}`}
                name_label={t("forms:home_contact_name_label")}
                name_ph={t("forms:home_contact_name_placeholder")}
                fname_label={t("forms:home_contact_firstname_label")}
                fname_ph={t("forms:home_contact_firstname_placeholder")}
                company_label={t("home_contact_company_label")}
                company_ph={t("forms:home_contact_company_placeholder")}
                phone_label={t("forms:home_contact_phone_label")}
                phone_ph={t("forms:home_contact_phone_placeholder")}
                email_label={t("forms:home_contact_email_label")}
                email_ph={t("forms:home_contact_email_placeholder")}
                message_label={t("forms:home_contact_message_label")}
                message_ph={t("forms:services_contact_message_placeholder")}
                terms_msg_p1={t("forms:home_contact_terms_p1")}
                terms_msg_p2={t("forms:home_contact_terms_p2")}
                send_btn={t("forms:home_contact_send_btn")}
                notif_at_least_one_error_msg={t(
                  "forms:global_error_at_least_one_msg"
                )}
                notif_sending_form_msg={t("forms:global_info_sending_form_msg")}
                success_message_sent_msg={t(
                  "forms:global_success_message_sent_msg"
                )}
                notif_unknown_error_msg={t("forms:global_error_unknown_msg")}
                notif_email_validation_error_msg={t(
                  "forms:notif_email_validation_error_msg"
                )}
                notif_lname_validation_error_msg={t(
                  "forms:notif_lname_validation_error_msg"
                )}
                notif_fname_validation_error_msg={t(
                  "forms:notif_fname_validation_error_msg"
                )}
                notif_number_validation_error_msg={t(
                  "forms:notif_number_validation_error_msg"
                )}
                notif_message_validation_error_msg={t(
                  "forms:notif_message_validation_error_msg"
                )}
              />
            </div>
          </div>
          <div className="form_block mobile_view">
            <h3>
              <Trans i18nKey="main:single-testimonial_contact_form-sidechat-title_html">
                Comme {{ clientname: "Javelo" }} contactez-nous et avançons
                ensemble!
              </Trans>
            </h3>
            <ExtContactForm
              contact_source={`${pageInfo.item_key}`}
              name_label={t("forms:home_contact_name_label")}
              name_ph={t("forms:home_contact_name_placeholder")}
              fname_label={t("forms:home_contact_firstname_label")}
              fname_ph={t("forms:home_contact_firstname_placeholder")}
              company_label={t("home_contact_company_label")}
              company_ph={t("forms:home_contact_company_placeholder")}
              phone_label={t("forms:home_contact_phone_label")}
              phone_ph={t("forms:home_contact_phone_placeholder")}
              email_label={t("forms:home_contact_email_label")}
              email_ph={t("forms:home_contact_email_placeholder")}
              message_label={t("forms:home_contact_message_label")}
              message_ph={t("forms:services_contact_message_placeholder")}
              terms_msg_p1={t("forms:home_contact_terms_p1")}
              terms_msg_p2={t("forms:home_contact_terms_p2")}
              send_btn={t("forms:home_contact_send_btn")}
              notif_at_least_one_error_msg={t(
                "forms:global_error_at_least_one_msg"
              )}
              notif_sending_form_msg={t("forms:global_info_sending_form_msg")}
              success_message_sent_msg={t(
                "forms:global_success_message_sent_msg"
              )}
              notif_unknown_error_msg={t("forms:global_error_unknown_msg")}
              notif_email_validation_error_msg={t(
                "forms:notif_email_validation_error_msg"
              )}
              notif_lname_validation_error_msg={t(
                "forms:notif_lname_validation_error_msg"
              )}
              notif_fname_validation_error_msg={t(
                "forms:notif_fname_validation_error_msg"
              )}
              notif_number_validation_error_msg={t(
                "forms:notif_number_validation_error_msg"
              )}
              notif_message_validation_error_msg={t(
                "forms:notif_message_validation_error_msg"
              )}
            />
          </div>
        </div>
      </div>

      {/* main content end */}
    </Layout>
  );
};

export default ClientTestimonialPage;
